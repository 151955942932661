import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Row from "react-bootstrap/Row"
import "./about.scss"
import Table from "react-bootstrap/Table"
import { connect } from "react-redux"

const mapStateToProps = ({ isLoggedin }) => {
  return {
    isLoggedin,
  }
}

const AboutPage = ({ data, isLoggedin }) => {
  return (
    <Layout>
      <div className="about-container">
        <Row>
          <h3 id="background">Background</h3>
        </Row>
        <Row>
          <ReactMarkdown source={data.strapiAboutPage.background} />
        </Row>
        <Row>
          <h3 id="methodology">Methodology</h3>
        </Row>
        <Row>
          <ReactMarkdown source={data.strapiAboutPage.methodology} />
        </Row>
        <Row>
          <h3 id="glossary">Glossary</h3>
        </Row>
        <Row>
          <ReactMarkdown source={data.strapiAboutPage.glossary} />
        </Row>
        <Row>
          <h3 id="about-us">About Us</h3>
        </Row>
        <Row>
          <ReactMarkdown source={data.strapiAboutPage.about_us} />
        </Row>
        <Row>
          <h3 id="acknowledgement">Acknowledgement</h3>
        </Row>
        <Row>
          <ReactMarkdown source={data.strapiAboutPage.acknowledgement} />
        </Row>
        <Row>
          <h3 id="citation">Citation</h3>
        </Row>
        <Row>
          <ReactMarkdown source={data.strapiAboutPage.citation} />
        </Row>

        {/* {isLoggedin ? ( */}
          
            <Row>
              <h3 id="data-download">Data Download</h3>
            </Row>
            <Row>
              <p>
                In order to download or use the data or reports from the <a href="https://github.com/dpasiliao/cropwatch-data">GitHub Repository</a>, you must agree to the Big Data Platform’s <a href="https://bigdata.cgiar.org/legal/">Terms of Use.</a>
              </p>
              
              {/* <Table size="sm">
                <thead>
                  <th>Country</th>
                  <th>Admin Level</th>
                  <th>Year</th>
                  <th>Download</th>
                </thead>
                <tbody>
                  {data.allStrapiData.edges.map(edge => {
                    return (
                      <tr key={edge.node.id}>
                        <td>{edge.node.country}</td>
                        <td>{edge.node.admin_level}</td>
                        <td>{edge.node.year}</td>
                        <td>
                          <a href={edge.node.csv[0].url}>CSV</a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table> */}
            </Row>
          
        {/* ) : null} */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiAboutPage {
      background
      methodology
      glossary
      about_us
      acknowledgement
      citation
    }
    allStrapiData {
      edges {
        node {
          country
          admin_level
          id
          year
          csv {
            url
          }
        }
      }
    }
  }
`
export default connect(mapStateToProps)(AboutPage)
